import React from "react";
import "../App.css";

function About() {
  return (
    <div className="scrollable scrollbarless">
      <svg
        className="aboutHeader"
        id="a"
        viewBox="0 0 564.21003 70.199997"
        version="1.1"
        width="564.21002"
        height="70.199997"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsSvg="http://www.w3.org/2000/svg"
      >
        <path
          d="m 102.46,70.19 c -2.76,0 -5.05,-0.46 -6.84,-1.38 -1.8,-0.92 -3.14,-2.14 -4.01,-3.65 -0.88,-1.51 -1.32,-3.17 -1.32,-4.97 0,-3.16 1.23,-5.66 3.69,-7.5 2.46,-1.84 5.97,-2.76 10.53,-2.76 h 8.56 v -0.59 c 0,-2.54 -0.69,-4.45 -2.07,-5.73 -1.38,-1.27 -3.17,-1.91 -5.36,-1.91 -1.93,0 -3.61,0.47 -5.04,1.42 -1.43,0.94 -2.29,2.34 -2.6,4.18 h -6.98 c 0.22,-2.37 1.02,-4.4 2.4,-6.09 1.38,-1.69 3.14,-2.98 5.27,-3.88 2.13,-0.9 4.46,-1.35 7.01,-1.35 4.56,0 8.09,1.2 10.6,3.59 2.5,2.39 3.75,5.65 3.75,9.77 V 69.41 H 114 l -0.59,-5.59 c -0.92,1.8 -2.26,3.31 -4.01,4.54 -1.76,1.23 -4.06,1.84 -6.91,1.84 z m 1.38,-5.66 c 1.89,0 3.48,-0.45 4.77,-1.35 1.29,-0.9 2.3,-2.09 3.03,-3.59 0.72,-1.49 1.17,-3.14 1.35,-4.94 h -7.77 c -2.76,0 -4.73,0.48 -5.89,1.45 -1.16,0.97 -1.74,2.17 -1.74,3.62 0,1.45 0.56,2.67 1.68,3.52 1.12,0.86 2.64,1.28 4.57,1.28 z"
          style={{ fill: "currentColor", fillOpacity: 1 }}
        />
        <path
          d="M 127.73,69.4 V 36.76 h 6.25 l 0.59,6.19 c 1.14,-2.15 2.73,-3.85 4.77,-5.1 2.04,-1.25 4.51,-1.88 7.4,-1.88 v 7.31 h -1.91 c -1.93,0 -3.65,0.32 -5.17,0.95 -1.51,0.64 -2.72,1.71 -3.62,3.22 -0.9,1.51 -1.35,3.61 -1.35,6.29 V 69.4 h -6.98 z"
          style={{ fill: "currentColor", fillOpacity: 1 }}
        />
        <path
          d="m 166.76,69.4 c -3.2,0 -5.75,-0.78 -7.63,-2.34 -1.89,-1.56 -2.83,-4.33 -2.83,-8.33 V 42.61 h -5.59 v -5.86 h 5.59 l 0.86,-8.29 h 6.12 v 8.29 h 9.21 v 5.86 h -9.21 v 16.12 c 0,1.8 0.38,3.04 1.15,3.72 0.77,0.68 2.09,1.02 3.98,1.02 h 3.75 v 5.92 h -5.4 z"
          style={{ fill: "currentColor", fillOpacity: 1 }}
        />
        <path
          d="m 191.51,70.19 c -4.12,0 -7.53,-1.01 -10.2,-3.03 -2.68,-2.02 -4.21,-4.7 -4.61,-8.03 h 7.04 c 0.35,1.49 1.18,2.78 2.5,3.85 1.32,1.08 3.05,1.61 5.2,1.61 2.15,0 3.64,-0.44 4.61,-1.32 0.96,-0.88 1.45,-1.89 1.45,-3.03 0,-1.67 -0.67,-2.8 -2.01,-3.39 -1.34,-0.59 -3.19,-1.13 -5.56,-1.61 -1.84,-0.39 -3.68,-0.92 -5.5,-1.58 -1.82,-0.66 -3.33,-1.59 -4.54,-2.8 -1.21,-1.21 -1.81,-2.84 -1.81,-4.9 0,-2.85 1.1,-5.23 3.29,-7.14 2.19,-1.91 5.26,-2.86 9.21,-2.86 3.64,0 6.59,0.88 8.85,2.63 2.26,1.75 3.59,4.23 3.98,7.44 h -6.71 c -0.22,-1.4 -0.87,-2.5 -1.94,-3.29 -1.08,-0.79 -2.51,-1.19 -4.31,-1.19 -1.8,0 -3.12,0.36 -4.08,1.09 -0.97,0.72 -1.45,1.66 -1.45,2.8 0,1.14 0.67,2.04 2.01,2.7 1.34,0.66 3.1,1.25 5.3,1.78 2.19,0.48 4.22,1.04 6.09,1.68 1.86,0.64 3.37,1.57 4.51,2.8 1.14,1.23 1.71,3.03 1.71,5.4 0.04,2.98 -1.11,5.46 -3.46,7.44 -2.35,1.97 -5.54,2.96 -9.58,2.96 z"
          style={{ fill: "currentColor", fillOpacity: 1 }}
        />
        <path
          d="m 225.63,70.19 c -3.16,0 -5.95,-0.75 -8.36,-2.24 -2.41,-1.49 -4.3,-3.53 -5.66,-6.12 -1.36,-2.59 -2.04,-5.53 -2.04,-8.82 0,-3.29 0.68,-6.22 2.04,-8.79 1.36,-2.57 3.26,-4.58 5.69,-6.05 2.44,-1.47 5.23,-2.21 8.39,-2.21 2.59,0 4.86,0.5 6.81,1.51 1.95,1.01 3.48,2.44 4.57,4.28 V 22 h 6.98 v 47.39 h -6.25 l -0.72,-5.07 c -1.05,1.54 -2.5,2.9 -4.34,4.08 -1.84,1.18 -4.21,1.78 -7.11,1.78 z m 1.25,-6.06 c 2.98,0 5.43,-1.03 7.34,-3.09 1.91,-2.06 2.86,-4.72 2.86,-7.96 0,-3.24 -0.95,-5.96 -2.86,-8 -1.91,-2.04 -4.35,-3.06 -7.34,-3.06 -2.99,0 -5.44,1.02 -7.37,3.06 -1.93,2.04 -2.9,4.71 -2.9,8 0,2.15 0.44,4.06 1.32,5.73 0.88,1.67 2.09,2.97 3.65,3.92 1.56,0.94 3.32,1.42 5.3,1.42 z"
          style={{ fill: "currentColor", fillOpacity: 1 }}
        />
        <path
          d="m 262.98,70.19 c -2.76,0 -5.05,-0.46 -6.84,-1.38 -1.8,-0.92 -3.14,-2.14 -4.01,-3.65 -0.88,-1.51 -1.32,-3.17 -1.32,-4.97 0,-3.16 1.23,-5.66 3.69,-7.5 2.46,-1.84 5.97,-2.76 10.53,-2.76 h 8.56 v -0.59 c 0,-2.54 -0.69,-4.45 -2.07,-5.73 -1.38,-1.28 -3.17,-1.91 -5.36,-1.91 -1.93,0 -3.61,0.47 -5.04,1.42 -1.43,0.94 -2.29,2.34 -2.6,4.18 h -6.98 c 0.22,-2.37 1.02,-4.4 2.4,-6.09 1.38,-1.69 3.14,-2.98 5.26,-3.88 2.12,-0.9 4.46,-1.35 7.01,-1.35 4.56,0 8.09,1.2 10.6,3.59 2.5,2.39 3.75,5.65 3.75,9.77 v 20.07 h -6.05 l -0.59,-5.59 c -0.92,1.8 -2.26,3.31 -4.01,4.54 -1.75,1.23 -4.06,1.84 -6.91,1.84 z m 1.38,-5.66 c 1.89,0 3.48,-0.45 4.77,-1.35 1.29,-0.9 2.3,-2.09 3.03,-3.59 0.72,-1.49 1.17,-3.14 1.35,-4.94 h -7.77 c -2.76,0 -4.73,0.48 -5.89,1.45 -1.16,0.97 -1.74,2.17 -1.74,3.62 0,1.45 0.56,2.67 1.68,3.52 1.12,0.85 2.64,1.28 4.57,1.28 z"
          style={{ fill: "currentColor", fillOpacity: 1 }}
        />
        <path
          d="m 301.04,69.4 c -3.2,0 -5.75,-0.78 -7.63,-2.34 -1.89,-1.56 -2.83,-4.33 -2.83,-8.33 V 42.61 h -5.59 v -5.86 h 5.59 l 0.86,-8.29 h 6.12 v 8.29 h 9.21 v 5.86 h -9.21 v 16.12 c 0,1.8 0.38,3.04 1.15,3.72 0.77,0.68 2.09,1.02 3.98,1.02 h 3.75 v 5.92 h -5.4 z"
          style={{ fill: "currentColor", fillOpacity: 1 }}
        />
        <path
          d="m 323.79,70.19 c -2.76,0 -5.05,-0.46 -6.84,-1.38 -1.8,-0.92 -3.14,-2.14 -4.01,-3.65 -0.88,-1.51 -1.32,-3.17 -1.32,-4.97 0,-3.16 1.23,-5.66 3.69,-7.5 2.46,-1.84 5.97,-2.76 10.53,-2.76 h 8.56 v -0.59 c 0,-2.54 -0.69,-4.45 -2.07,-5.73 -1.38,-1.28 -3.17,-1.91 -5.36,-1.91 -1.93,0 -3.61,0.47 -5.04,1.42 -1.43,0.94 -2.29,2.34 -2.6,4.18 h -6.98 c 0.22,-2.37 1.02,-4.4 2.4,-6.09 1.38,-1.69 3.14,-2.98 5.26,-3.88 2.12,-0.9 4.46,-1.35 7.01,-1.35 4.56,0 8.09,1.2 10.6,3.59 2.5,2.39 3.75,5.65 3.75,9.77 v 20.07 h -6.05 l -0.59,-5.59 c -0.92,1.8 -2.26,3.31 -4.01,4.54 -1.75,1.23 -4.06,1.84 -6.91,1.84 z m 1.38,-5.66 c 1.89,0 3.48,-0.45 4.77,-1.35 1.29,-0.9 2.3,-2.09 3.03,-3.59 0.72,-1.49 1.17,-3.14 1.35,-4.94 h -7.77 c -2.76,0 -4.73,0.48 -5.89,1.45 -1.16,0.97 -1.74,2.17 -1.74,3.62 0,1.45 0.56,2.67 1.68,3.52 1.12,0.85 2.64,1.28 4.57,1.28 z"
          style={{ fill: "currentColor", fillOpacity: 1 }}
        />
        <path
          d="m 367.42,70.19 c -2.54,0 -4.79,-0.5 -6.75,-1.51 -1.96,-1.01 -3.5,-2.44 -4.64,-4.28 l -0.72,5 h -6.25 V 22.01 h 6.98 v 19.81 c 1.05,-1.54 2.5,-2.9 4.34,-4.08 1.84,-1.18 4.21,-1.78 7.11,-1.78 3.16,0 5.94,0.75 8.36,2.24 2.41,1.49 4.31,3.53 5.69,6.12 1.38,2.59 2.07,5.53 2.07,8.82 0,3.29 -0.69,6.22 -2.07,8.79 -1.38,2.57 -3.28,4.58 -5.69,6.05 -2.41,1.47 -5.22,2.21 -8.42,2.21 z m -1.18,-6.06 c 2.98,0 5.44,-1.02 7.37,-3.06 1.93,-2.04 2.9,-4.71 2.9,-8 0,-2.15 -0.44,-4.06 -1.32,-5.73 -0.88,-1.67 -2.08,-2.97 -3.62,-3.92 -1.54,-0.94 -3.31,-1.42 -5.33,-1.42 -2.98,0 -5.43,1.03 -7.34,3.09 -1.91,2.06 -2.86,4.72 -2.86,7.96 0,3.24 0.95,5.96 2.86,8 1.91,2.04 4.35,3.06 7.34,3.06 z"
          style={{ fill: "currentColor", fillOpacity: 1 }}
        />
        <path
          d="m 400.86,70.19 c -2.76,0 -5.05,-0.46 -6.84,-1.38 -1.8,-0.92 -3.14,-2.14 -4.01,-3.65 -0.88,-1.51 -1.32,-3.17 -1.32,-4.97 0,-3.16 1.23,-5.66 3.69,-7.5 2.46,-1.84 5.97,-2.76 10.53,-2.76 h 8.56 v -0.59 c 0,-2.54 -0.69,-4.45 -2.07,-5.73 -1.38,-1.28 -3.17,-1.91 -5.36,-1.91 -1.93,0 -3.61,0.47 -5.04,1.42 -1.43,0.94 -2.29,2.34 -2.6,4.18 h -6.98 c 0.22,-2.37 1.02,-4.4 2.4,-6.09 1.38,-1.69 3.14,-2.98 5.26,-3.88 2.12,-0.9 4.46,-1.35 7.01,-1.35 4.56,0 8.09,1.2 10.6,3.59 2.5,2.39 3.75,5.65 3.75,9.77 v 20.07 h -6.05 l -0.59,-5.59 c -0.92,1.8 -2.26,3.31 -4.01,4.54 -1.75,1.23 -4.06,1.84 -6.91,1.84 z m 1.38,-5.66 c 1.89,0 3.48,-0.45 4.77,-1.35 1.29,-0.9 2.3,-2.09 3.03,-3.59 0.72,-1.49 1.17,-3.14 1.35,-4.94 h -7.77 c -2.76,0 -4.73,0.48 -5.89,1.45 -1.16,0.97 -1.74,2.17 -1.74,3.62 0,1.45 0.56,2.67 1.68,3.52 1.12,0.85 2.64,1.28 4.57,1.28 z"
          style={{ fill: "currentColor", fillOpacity: 1 }}
        />
        <path
          class="b"
          d="M 426.13,69.4 V 36.76 h 6.19 l 0.53,5.73 c 1.01,-2.02 2.49,-3.61 4.44,-4.77 1.95,-1.16 4.2,-1.74 6.75,-1.74 3.95,0 7.05,1.23 9.31,3.69 2.26,2.46 3.39,6.12 3.39,10.99 v 18.76 h -6.91 V 51.39 c 0,-6.32 -2.59,-9.48 -7.77,-9.48 -2.59,0 -4.73,0.92 -6.42,2.76 -1.69,1.84 -2.53,4.48 -2.53,7.9 v 16.85 h -6.98 z"
          style={{ fill: "currentColor", fillOpacity: 1 }}
        />
        <path
          d="M 464.43,69.4 V 22.01 h 6.98 v 28.23 l 12.5,-13.49 h 8.36 l -13.89,14.81 15.93,17.84 h -8.82 L 471.41,52.68 V 69.4 Z"
          style={{ fill: "currentColor", fillOpacity: 1 }}
        />
        <path
          d="m 511.26,70.19 c -3.2,0 -6.04,-0.71 -8.52,-2.14 -2.48,-1.42 -4.42,-3.41 -5.82,-5.96 -1.41,-2.54 -2.11,-5.51 -2.11,-8.88 0,-3.37 0.69,-6.43 2.07,-9.02 1.38,-2.59 3.31,-4.61 5.79,-6.05 2.48,-1.45 5.36,-2.17 8.65,-2.17 3.29,0 5.99,0.71 8.36,2.14 2.37,1.43 4.21,3.32 5.53,5.69 1.32,2.37 1.97,4.98 1.97,7.83 0,0.44 -0.01,0.91 -0.03,1.42 -0.02,0.5 -0.06,1.06 -0.1,1.68 h -25.4 c 0.22,3.16 1.26,5.56 3.13,7.21 1.86,1.65 4.02,2.47 6.48,2.47 1.97,0 3.63,-0.45 4.97,-1.35 1.34,-0.9 2.34,-2.12 3,-3.65 h 6.98 c -0.88,3.07 -2.62,5.64 -5.23,7.7 -2.61,2.06 -5.85,3.09 -9.71,3.09 z m 0,-28.5 c -2.33,0 -4.39,0.69 -6.19,2.07 -1.8,1.38 -2.9,3.46 -3.29,6.22 h 18.43 c -0.13,-2.54 -1.03,-4.56 -2.7,-6.05 -1.67,-1.49 -3.75,-2.24 -6.25,-2.24 z"
          style={{ fill: "currentColor", fillOpacity: 1 }}
        />
        <path
          d="M 533.6,69.4 V 36.76 h 6.19 l 0.53,5.73 c 1.01,-2.02 2.49,-3.61 4.44,-4.77 1.95,-1.16 4.2,-1.74 6.75,-1.74 3.95,0 7.05,1.23 9.31,3.69 2.26,2.46 3.39,6.12 3.39,10.99 V 69.42 H 557.3 V 51.39 c 0,-6.32 -2.59,-9.48 -7.77,-9.48 -2.59,0 -4.73,0.92 -6.42,2.76 -1.69,1.84 -2.53,4.48 -2.53,7.9 v 16.85 h -6.98 z"
          style={{ fill: "currentColor", fillOpacity: 1 }}
        />
        <g id="g850" transform="scale(0.14290747)">
          <path
            style={{ fill: "currentColor", fillOpacity: 1 }}
            d="M 241.27,482.55 C 108.24,482.55 0,374.32 0,241.27 0,108.22 108.24,0 241.27,0 374.3,0 482.55,108.24 482.55,241.27 h -60.9 C 421.65,141.82 340.74,60.9 241.28,60.9 141.82,60.9 60.9,141.82 60.9,241.27 c 0,99.45 80.91,180.38 180.37,180.38 z"
          />
          <path
            style={{ fill: "#e55440" }}
            d="M 422.23,422.23 V 317.02 h 60.32 V 482.55 H 317.02 v -60.32 z"
          />
          <path
            style={{ fill: "currentColor", fillOpacity: 1 }}
            d="m 347.18,241.28 a 37.869999,37.869999 0 0 1 -37.87,37.87 37.869999,37.869999 0 0 1 -37.87,-37.87 37.869999,37.869999 0 0 1 37.87,-37.87 37.869999,37.869999 0 0 1 37.87,37.87 z"
          />
        </g>
      </svg>

      <hr />

      <p className="intro">
        Artsorakelet er utviklet av Artsdatabanken i samarbeid med Naturalis
        Biodiversity Center. Appen prøver å artsbestemme bilder ved hjelp av
        maskinlæring.
      </p>

      <p>
        Gjenkjenningsmodellen trenes hos Naturalis Biodiversity Center, med
        bilder som er offentlig tilgjengelig på artsobservasjoner.no. Når appen
        brukes sier modellen hva det ligner mest på av artene den har blitt
        trent med. Dette innebærer at den kun kan foreslå arter som finnes i
        Norsk natur og som har blitt rapportert med bilder. Den kjenner altså
        kun viltlevende arter (ingen mennesker, husdyr, rene hageplanter, osv.)
        og gir svar på artsnivå (og noen underarter).
      </p>

      <p>
        Bilder sendes til serveren til gjenkjenning. Bilder og brukerinformasjon
        blir ikke tilgjengelige for Artsdatabanken eller andre.
      </p>

      <p>
        Du kan lese mer om Artsorakelet på{" "}
        <a href="https://www.artsdatabanken.no/Pages/299643">
          Artsdatabankens nettsider
        </a>
        . Spørsmål og tilbakemelding kan sendes til{" "}
        <a href="mailto:support@artsobservasjoner.no">
          support@artsobservasjoner.no
        </a>
        .
      </p>

      <p>
        <svg
          className="aboutLogo"
          id="a"
          viewBox="0 0 68.96 68.959999"
          version="1.1"
          width="68.959999"
          height="68.959999"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsSvg="http://www.w3.org/2000/svg"
        >
          <path
            d="M 34.479285,68.96 C 15.468305,68.96 0,53.493124 0,34.479285 0,15.465446 15.468305,0 34.479285,0 53.490266,0 68.96,15.468305 68.96,34.479285 h -8.703065 c 0,-14.212148 -11.562644,-25.7762201 -25.776221,-25.7762201 -14.213577,0 -25.7776491,11.5640721 -25.7776491,25.7762201 0,14.212148 11.5626431,25.77765 25.7762201,25.77765 z"
            id="path829"
            style={{ strokeWidth: 0.142907, fill: "currentColor" }}
          />
          <path
            style={{ fill: "#e55440", strokeWidth: 0.142907 }}
            d="M 60.339821,60.339821 V 45.304526 H 68.96 V 68.96 H 45.304526 v -8.620179 z"
          />
          <path
            style={{ fill: "currentColor", strokeWidth: 0.142907 }}
            d="m 49.614615,34.480714 a 5.4119057,5.4119057 0 0 1 -5.411905,5.411906 5.4119057,5.4119057 0 0 1 -5.411906,-5.411906 5.4119057,5.4119057 0 0 1 5.411906,-5.411906 5.4119057,5.4119057 0 0 1 5.411905,5.411906 z"
          />
        </svg>
        <img src="Naturalis.svg" className="aboutLogo" alt="Naturalis" />
      </p>
    </div>
  );
}

export default About;
